import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'about_you',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'Your company',
          isRequired: false,
          isEnabled: true,
          hasHelper: true,
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Personal assets',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Personal income',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Are you considered as a PEP?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Are you, or any beneficial owner of the company, a US Person?',
          type: FieldTypeEnum.radio,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: ['yes', 'no'],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Source of wealth',
          type: FieldTypeEnum.select,
          isMulti: true,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: [
            'Inherited Wealth',
            'Business Ownership',
            'Investments',
            'Real Estate Holdings',
            'Savings and Deposits',
            'Employment Income',
            'Intellectual Property',
            'Retirement Accounts',
            'Art and Collectibles',
            'Royalties',
            'Legal Settlements',
            'Venture Capital or Angel Investing',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Source of funds',
          type: FieldTypeEnum.select,
          isMulti: true,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          options: [
            'Salary/Wages',
            'Business Revenue',
            'Investment Income',
            'Sale of Property',
            'Inheritance',
            'Gifts/Donations',
            'Savings',
            'Loan Proceeds',
            'Insurance Payout',
            'Retirement Funds',
            'Dividends/Interest',
            'Legal Settlements',
            'Scholarship/Grants',
            'Lottery/Gambling Winnings',
          ],
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Estimated deposits on Nilos account (monthly)',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
        {
          id: 'Expected monthly outgoing FIAT transactions',
          type: FieldTypeEnum.number,
          isRequired: true,
          isEnabled: true,
          addons: {
            right: '€',
          },
          propertyType: FieldPropertyTypeEnum.metadata,
        },
      ],
    },
  },
  {
    id: 'payment_details',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'iban',
          nested: 'banking_information',
          type: FieldTypeEnum.iban,
          isRequired: true,
          isEnabled: true,
          hasHelper: true,
          propertyType: FieldPropertyTypeEnum.default,
          entityType: FieldEntityTypeEnum.individual,
        },
        {
          id: 'bic',
          nested: 'banking_information',
          type: FieldTypeEnum.bic,
          isRequired: true,
          isEnabled: true,
          propertyType: FieldPropertyTypeEnum.default,
          entityType: FieldEntityTypeEnum.individual,
        },
      ],
    },
  },
  // 2023.12.21 - disable this for now until we receive the latest version of T&Cs
  // {
  //   id: 'pdf_viewer_terms',
  //   type: StepTypeEnum.terms_and_conditions,
  //   config: {
  //     pdfUrl:
  //       'https://nilos-kyc.onboarding.dotfile.com/assets/terms-and-conditions.pdf',
  //   },
  // },
];
