const brandColors = {
  primary: '#23272F',
  secondary: '#5B75FF',
};

const sidebarColors = {
  backgroundColor: '#161616',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#5B75FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
